import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RouterLinkDisableDirective } from './directives/router-link-disable.directive';
import { NgxMaskDirective } from 'ngx-mask';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AlertModule } from 'ngx-bootstrap/alert';
import {
  BsDatepickerConfig,
  BsDatepickerModule,
  BsLocaleService,
} from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { ModalModule } from 'ngx-bootstrap/modal';
import { GlobalErrorHandler } from './global-error-handler/global-error-handler';
import { FooterComponent } from './components/layout/footer/footer.component';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { AnimatedContainerComponent } from './components/animated-container/animated-container.component';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    RouterLinkDisableDirective,
    NgxMaskDirective,
    TooltipModule.forRoot(),
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    FooterComponent,
    RxReactiveFormsModule,
    AnimatedContainerComponent,
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    RouterLinkDisableDirective,
    NgxMaskDirective,
    TooltipModule,
    ModalModule,
    BsDatepickerModule,
    FooterComponent,
    RxReactiveFormsModule,
    AnimatedContainerComponent,
    AlertModule,
  ],
  providers: [
    {
      provide: BsDatepickerConfig,
      useFactory: () => {
        let service = new BsDatepickerConfig();
        service.dateInputFormat = 'DD/MM/YYYY';
        service.rangeInputFormat = 'DD/MM/YYYY';
        service.containerClass = 'theme-dark-blue';
        service.monthTitle = 'MMMM';
        service.showWeekNumbers = false;
        return service;
      },
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
})
export class SharedModule {
  constructor(private _bsLocaleService: BsLocaleService) {
    ptBrLocale.invalidDate = 'Data inválida';
    defineLocale('pt-br', ptBrLocale);
    this._bsLocaleService.use('pt-br');
  }

  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
