import { ErrorDetails } from "./error-details";

export class ErrorReader {
    public static getStatus0(exception: any): ErrorDetails {
        let retorno = new ErrorDetails('Oops!');
        let rawMessage = ''

        if (exception.message) {
            rawMessage = exception.message.toLowerCase();
        }

        if (rawMessage) {
            if (rawMessage.indexOf('unknown url') > -1) {
                retorno.message = "A URL solicitada não foi encontrada.";
            } else {
                retorno.message = exception.message;
            }
        }

        if (!retorno.message) {
            retorno.message = JSON.stringify(exception);
        }

        return retorno;
    }

    public static getJs(exception: any): ErrorDetails {
        let retorno = new ErrorDetails('Oops!');

        if (exception.error && exception.error.message) {
            retorno.message = exception.error.message;
        } else if (exception.message) {
            //Erros de HTTP
            if (exception.message.toLowerCase().indexOf('the request is invalid') > -1) {
                retorno.message = 'Ocorreu um erro na sua solicitação (requisição inválida).';
            } else if (exception.message.toLowerCase().indexOf('could not resolve') > -1) {
                retorno.message = 'O endereço solicitado não foi encontrado.';
            } else {
                retorno.message = exception.message;
            }
        } else {
            retorno.message = exception.message;
        }

        return retorno;
    }
}