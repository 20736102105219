import { datadog } from './datadog';

export const environment = {
  production: true,
  name: 'Production',
  api: {
    uri: 'https://api.auth.perseus.com.br',
  },
  aplicacao: {
    nome: 'Perseus TI',
    id: 'Perseus.Auth',
  },
  keys: {
    userInfo: 'X-User-Info',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyB9z7mp43AZRW96PxYXu2Rkdmkd97wy97k',
    authDomain: 'secure.perseus.com.br',
    projectId: 'perseus-auth-login',
    storageBucket: 'perseus-auth-login.appspot.com',
    messagingSenderId: '693233172131',
    appId: '1:693233172131:web:27e5255a26d50975d5c916',
    measurementId: 'G-D5TKKZ68ZY',
  },
  datadogRumConfig: {
    applicationId: datadog.applicationId,
    clientToken: datadog.clientToken,
    site: datadog.site,
    service: datadog.service,
    env: 'production',
    version: datadog.version,
    sessionSampleRate: 80,
    sessionReplaySampleRate: 30,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [
      {
        match: 'https://api.auth.perseus.com.br',
        propagatorTypes: ['datadog', 'tracecontext'],
      },
    ],
  },
  datadogLogsConfig: {
    clientToken: datadog.clientToken,
    site: datadog.site,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    env: 'production',
    service: datadog.service,
    version: datadog.version,
    trackSessionAcrossSubdomains: true,
  },
};
