import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';

@Component({
  selector: 'app-animated-container',
  standalone: true,
  imports: [],
  templateUrl: './animated-container.component.html',
  styleUrl: './animated-container.component.scss',
  animations: [
    trigger('contentOpen', [
      state('ready', style({ opacity: 1 })),
      transition('void => ready', [
        style({ opacity: 0, transform: 'translate(-30px, -15px)' }),
        animate('300ms 0s ease-in-out')
      ])
    ])
  ]
})
export class AnimatedContainerComponent {
  protected contentState = 'ready'
}
