export interface LoginParams {
  nomeUsuario: string;
  senha: string;
  aplicacaoId?: string | null;
  loginExterno?: boolean;
  providerLink?: string | null;
}

export interface LoginSocialParams {
  providerAccessToken: string;
  provider: string;
  aplicacaoId?: string | null;
  loginExterno?: boolean;
}

export interface LoginResult {
  token: string;
  refreshToken: string;
  usuarioInfo: UsuarioInfo;
}

export interface LoginSocialResult {
  providerTokenLink: string;
}

export interface LoginExternoResult {
  token: string;
  usuarioId: string;
}

export interface UsuarioInfo {
  id: string;
  nomeCompleto: string;
  apelido: string;
  avatar?: string;
  email: string;
}

export enum LoginProvider {
  Google = 'Google',
  Microsoft = 'Microsoft',
  Facebook = 'Facebook',
}

export interface LoginExternalProviderError {
  message: string;
}

export interface LoginExternalProviderSuccess {
  provider: LoginProvider;
  oAuthToken: string;
}

export function isLoginResult(
  result: LoginResult | LoginSocialResult | LoginExternoResult
): result is LoginResult {
  return 'usuarioInfo' in result;
}

export function isLoginExternoResult(
  result: LoginResult | LoginSocialResult | LoginExternoResult
): result is LoginExternoResult {
  return 'usuarioId' in result;
}

export function isLoginSocialResult(
  result: LoginResult | LoginSocialResult | LoginExternoResult
): result is LoginSocialResult {
  return 'providerTokenLink' in result;
}
