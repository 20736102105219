<div class="actions-footer">
  <a
    href="https://perseus.com.br/politica-e-privacidade"
    target="_blank"
    alt="Link para termos de uso"
    >Termos de uso</a
  >
  <a
    href="https://perseus.com.br/politica-e-privacidade"
    target="_blank"
    alt="Link para política de privacidade"
    >Políticas de privacidade</a
  >
</div>
