import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { ErrorReader } from './error-reader';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { NotificacaoService } from '../../core/services/notificacao/notificacao.service';
import { TipoNotificacao } from '../../core/services/notificacao/notificacao.model';
import { ErrorDetails } from './error-details';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private ngZone: NgZone, private _injector: Injector) {}

  handleError(error: any): void {
    if (!environment.production) {
      console.log(error);
    }

    if (error.status == 404) {
      let router = this._injector.get(Router);
      router.navigate(['/404'], { queryParamsHandling: 'preserve' });
    } else {
      this.ngZone.run(() => {
        let errorDetails = this.handleErrorDetails(error);
        this._injector
          .get(NotificacaoService)
          .show(
            TipoNotificacao.erro,
            errorDetails.message,
            errorDetails.title,
            {
              timeOut: 5000,
              progressBar: true,
              closeButton: true,
            }
          );
      });
    }
  }

  private handleErrorDetails(error: any): ErrorDetails {
    let retorno = new ErrorDetails('Oops!');

    if (error instanceof HttpErrorResponse) {
      if (!navigator.onLine) {
        retorno.message = 'Não há conexão de internet.';
      } else {
        // Handle Http Error (error.status === 403, 404...)
        if (error.status == 0) {
          retorno = ErrorReader.getStatus0(error);
        } else if (error.status == 400) {
          retorno.message = error.error.message;
        }
      }
    } else {
      // Handle Client Error (Angular Error, ReferenceError...)
      retorno = ErrorReader.getJs(error);
    }

    return retorno;
  }
}
