import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';

@Injectable({
  providedIn: 'root',
})
export class RumService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  init() {
    if (environment.production && isPlatformBrowser(this.platformId)) {
      datadogRum.init(<RumInitConfiguration>environment.datadogRumConfig);
    }
  }

  stopSession() {
    if (environment.production && isPlatformBrowser(this.platformId)) {
      datadogRum.stopSession();
    }
  }

  setUserSession(id: string, name: string, email: string) {
    if (environment.production && isPlatformBrowser(this.platformId)) {
      datadogRum.setUser({ id: id, name: name, email: email });
    }
  }
}
